import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment.prod";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PaymentModel} from "../_models/payment.model";

@Injectable({
    providedIn: 'root'
})
export class PaymentService{
    private  url = environment.host+'payment/create';
    private headers = new HttpHeaders();
    constructor(private http: HttpClient) {
    }
    pay(pay:PaymentModel) {
        return this.http.post(this.url,pay,  {responseType: 'text'});
    }
}
