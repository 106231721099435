<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
        <p>{{Content.paragraph}}</p>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div style="border-radius: 40px" class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input style="border-radius: 40px" required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Votre Nom">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Champs Obligatoire!</div>
                                        <div *ngIf="name.errors.minlength">Le nom doit contenir au moins {{ name.errors.minlength.requiredLength }} caractère.</div>
                                        <div *ngIf="name.errors.maxlength">Le nom doit contenir au maximum 50 caractères.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input style="border-radius: 40px" required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Votre adresse E-mail">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Champs Obligatoire!</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input style="border-radius: 40px" required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Votre numéro de téléphone">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Champs Obligatoire!</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input style="border-radius: 40px" required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="Objet">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Champs Obligatoire!</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea style="border-radius: 40px" required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Tapez votre message..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Champs Obligatoire!</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Envoyer le message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div style="border-radius: 40px" class="contact-info-list">
                    <div class="contact-info-box">
                        <h3>Adresse</h3>
                        <p>Immeuble Essadi Tour C-D</p>
                    </div>
                    <div class="contact-info-box">
                        <h3>Contacts</h3>
                        <p>Mobile: <a href="tel:+44457895789">+216 93 317 718</a></p>
                        <p>E-mail: <a href="mailto:hello@marxa.com">Summitsells1@gmail.ocm</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
</div>
