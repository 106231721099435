<app-navbar-style-one _ngcontent-ng-c3921605814="" _nghost-ng-c405908009="">
    <div class="navbar-area" ng-reflect-ng-class="[object Object]">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <a routerlink="/" class="navbar-brand" ng-reflect-router-link="/" href="/">
                    <img src="assets/img/LOGO3.png" alt="logo" style="width: 250px; padding-top: 10px;">
                </a>
                <button type="button" class="navbar-toggler">
                    <span class="burger-menu">
                        <span class="top-bar">

                        </span>
                        <span class="middle-bar">

                        </span>
                        <span class="bottom-bar">

                        </span>
                    </span>
                </button>
                <div id="navbarSupportedContent" class="collapse navbar-collapse">
                    <ul class="navbar-nav">
                        <li class="nav-item megamenu">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">Evénements
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="submenu-title">Lieux
                                            </h6>
                                            <ul class="megamenu-submenu">
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Tunis
                                                        <span class="count">6
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Nabeul
                                                        <span class="count">5
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Beja
                                                        <span class="count">4
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Ain Drahem
                                                        <span class="count">3
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Djerba
                                                        <span class="count">2
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Tozeur
                                                        <span class="count">1
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col">
                                            <h6 class="submenu-title">Catégories
                                            </h6>
                                            <ul class="megamenu-submenu">
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Randonnée
                                                        <span class="count">1
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Camping
                                                        <span class="count">2
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Safari
                                                        <span class="count">3
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Marathon
                                                        <span class="count">4
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Observation d'oiseaux
                                                        <span class="count">5
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Atelier de méditation
                                                        <span class="count">6
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item megamenu">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">Agences
                            </a>
                            <ul class="dropdown-menu" style="width: 350px;">
                                <li class="nav-item">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="submenu-title">Types d'activité
                                            </h6>
                                            <ul class="megamenu-submenu">
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Randonnée
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Camping
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Safari
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Marathon
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Observation d'oiseaux
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerlink="/search-page" ng-reflect-router-link="/search-page" href="/search-page">Atelier de méditation
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">Partenaires
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerlink="/locations" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/locations" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/locations">Ministère du tourisme
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/categories" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/categories" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/categories">Decathlon
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/how-it-works" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/how-it-works" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/how-it-works">Pristine
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/locations" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/locations" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/locations">Tunisair
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/categories" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/categories" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/categories">The Dreamer
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/how-it-works" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/how-it-works" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/how-it-works">Magic Hotels
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li  class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">A propos
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerlink="/blog-grid" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/blog-grid" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/blog-grid">Qui sommes-nous?
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerlink="/contact" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/contact" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/contact">Nous contacter
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerlink="/search-page" routerlinkactive="active" class="nav-link" ng-reflect-router-link="/search-page" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]" href="/search-page">Boutique
                            </a>
                        </li>
                    </ul>
                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <form novalidate="" class="search-box ng-untouched ng-pristine ng-valid">
                                <label>
                                    <i class="bx bx-search">

                                    </i>
                                </label>
                                <input type="text" placeholder="Chercher événement" class="input-search">
                            </form>
                        </div>
                        <div class="option-item">
                            <a routerlink="/cart" class="wishlist-btn" ng-reflect-router-link="/cart" href="/cart">
                                <i class="bx bx-heart">

                                </i>
                            </a>
                        </div>
                        <div class="option-item">
                            <a routerlink="/cart" class="cart-btn" ng-reflect-router-link="/cart" href="/cart">
                                <i class="bx bx-shopping-bag">

                                </i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</app-navbar-style-one>
