<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost;">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/{{Content.detailsLink}}" class="d-block">
                        <img style="border-radius: 25px" [src]="Content.image" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <ul class="meta">
                        <li><i class="bx bx-purchase-tag"></i> <a routerLink="/{{Content.tagLink}}">{{Content.tag}}</a></li>
                    </ul>
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.detailsText}} <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
