<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="ng-star-inserted" style=""  *ngIf="products.length !=0">
    <div class="container-fluid">
        <div class="row">
            <div   class="col-sm-12">
                <div style="margin-left:150px; margin-top:30px; width: 1200px" class="card">
                    <div class="card-header">
                        <h5>Cart
                        </h5>
                    </div>
                    <div class="card-body" >
                        <div class="row">
                            <div class="order-history table-responsive wishlist">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Prdouct
                                        </th>
                                        <th>Prdouct Name
                                        </th>
                                        <th>Price
                                        </th>
                                        <th>Quantity
                                        </th>
                                        <th>Action
                                        </th>
                                        <th>Total
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of products">
                                        <td>
                                            <img [src]=item.imageToShow alt="#" class="img-fluid img-40">
                                        </td>
                                        <td>
                                            <div class="product-name">
                                                Ticket de {{item.name}}
                                            </div>
                                        </td>
                                        <td>{{item.ticket_price}}$
                                        </td>
                                        <td>
                                            <div  class="quantity">
                                                <button type="button" class="button-quantity" (click)="minus(item)"><i class="fa fa-minus" ></i></button>
                                                <input type="text" class="input-quantity" [value]="item.quantity">
                                                <button type="button" class="button-quantity" (click)="plus(item)"><i class="fa fa-plus" ></i></button>
                                            </div>
                                        </td>
                                        <td> <div _nghost-ng-c1040871746="" (click)="removeItem(item)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle" _ngcontent-ng-c1040871746="">
                                                <circle cx="12" cy="12" r="10">

                                                </circle>
                                                <line x1="15" y1="9" x2="9" y2="15">

                                                </line>
                                                <line x1="9" y1="9" x2="15" y2="15">

                                                </line>
                                            </svg>
                                        </div></td>
                                        <td>{{item.total}}$</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="input-group apply-input">
                                                <input type="text" placeholder="Enter coupan code" class="form-control me-2">
                                                <a href="javascript:void(0);" class="btn btn-primary">Apply
                                                </a>
                                            </div>
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td class="total-amount">
                                            <h6 class="m-0 text-end">
                                                <span class="f-w-600">Total Price :
                                                </span>
                                            </h6>
                                        </td>
                                        <td>
                                            <span>{{ grandTotal }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" class="text-end">
                                            <a href="ecommerce/products" class="btn btn-secondary cart-btn-transform">continue shopping
                                            </a>
                                        </td>
                                        <td>
                                            <a  class="btn btn-success cart-btn-transform" (click)="pay()">check out
                                            </a>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="margin-left:150px; margin-top:30px;  margin-bottom:30px;" class="cart-totals">
                    <a routerLink="/checkout" class="default-btn">Proceed to Checkout</a>
                </div>
            </div>
        </div>
    </div>
</div>



