import { Component, OnInit } from '@angular/core';
import {CartService} from "../../../_services/cart.service";
import {HttpErrorResponse} from "@angular/common/http";
import {PaymentModel} from "../../../_models/payment.model";
import {PaymentService} from "../../../_services/payment.service";

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
    quantity:number=1;
    i=1;
    payment:PaymentModel;

    public products : any = [];
    public grandTotal !: number;
    constructor(private cartService : CartService,private  paymentService:PaymentService,
    ) { }




    ngOnInit(): void {
        this.cartService.getProducts()
            .subscribe(res=>{
                this.products = res;
                this.grandTotal = this.cartService.getTotalPrice();
            })
        this.products.forEach((item:any) => {
            item.ticket_price=item.total
        })
        this.payment = {
            amount:"",
            description:"",
            method:""
        }
    }

    pageTitle = [
        {
            bgImage: `assets/img/e-commerce.jpg`,
            title: `Votre panier`,
        }
    ]
    plus(item:any){

        item.quantity++;
        item.total=item.quantity*item.ticket_price;
        item.total =  item.total.toFixed(1);
        this.grandTotal = this.cartService.getTotalPrice();
    }

    minus(item:any){
        if(item.quantity !=1){
            item.quantity--;

item.total=item.quantity*item.ticket_price;
            item.total =  item.total.toFixed(1);
            this.grandTotal = this.cartService.getTotalPrice();

        }
    }
    removeItem(item: any){
        this.cartService.removeCartItem(item);
    }
    emptycart(){
        this.cartService.removeAllCart();
    }
    pay() {
        this.payment.amount=this.grandTotal.toString();
        this.payment.description="Achat de SummitSells"
        this.paymentService.pay(this.payment).subscribe(
            (approvalUrl: string)   =>{
                window.location.href = approvalUrl;

            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        );
    }
}
