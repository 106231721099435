<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6" *ngFor="let Content of singleBlogPost | paginate: { itemsPerPage: 4, currentPage: blogRightSidebar }">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/{{Content.detailsLink}}" class="d-block">
                                    <img [src]="Content.image" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <ul class="meta">
                                    <li><i class="bx bx-purchase-tag"></i> <a routerLink="/{{Content.tagLink}}">{{Content.tag}}</a></li>
                                </ul>
                                <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                                <p>{{Content.paragraph}}</p>
                                <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.detailsText}} <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogRightSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>

                    <div class="widget widget_marxa_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">What Is Social Media Marketing for Online Business</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 25, 2023</li>
                                    <li><i class='bx bx-time'></i> 2 min</li>
                                </ul>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">How Much Does Professional Web Design Cost</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 24, 2023</li>
                                    <li><i class='bx bx-time'></i> 3 min</li>
                                </ul>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">5 Benefits of SEO Services for Shopify Stores</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 23, 2023</li>
                                    <li><i class='bx bx-time'></i> 2 min</li>
                                </ul>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">The 4 Ingredients to Digital Marketing Success</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> August 22, 2023</li>
                                    <li><i class='bx bx-time'></i> 5 min</li>
                                </ul>
                            </div>
                        </article>
                    </div>

                    <div class="widget widget_categories">
                        <h3 class="widget-title">Popular Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-right-sidebar"><i class='bx bx-glasses-alt'></i> Accessories</a></li>
                            <li><a routerLink="/blog-right-sidebar"><i class='bx bx-female'></i> Entertainment</a></li>
                            <li><a routerLink="/blog-right-sidebar"><i class='bx bx-bowling-ball'></i> Baby and Kids</a></li>
                            <li><a routerLink="/blog-right-sidebar"><i class='bx bx-happy-beaming'></i> Beauty</a></li>
                            <li><a routerLink="/blog-right-sidebar"><i class='bx bxs-t-shirt'></i> Clothing</a></li>
                        </ul>
                    </div>

                    <div class="widget widget_socials_link">
                        <h3 class="widget-title">Stay Connected</h3>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i>Facebook</a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i>Twitter</a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i>Linkedin</a></li>
                        </ul>
                    </div>

                    <div class="widget widget_custom_ads">
                        <a href="#" class="d-block" target="_blank">
                            <img src="assets/img/custom-ads1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-right-sidebar">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-right-sidebar">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-right-sidebar">Fearn <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-right-sidebar">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-right-sidebar">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-right-sidebar">Smart <span class="tag-link-count"> (1)</span></a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
